<template>
  <div class="d-flex justify-center login-recover-window" style="margin: 10vh auto;">
    <p class="additional-login-info" v-if="fromRoute !== undefined">Login to edit/register QR code: {{
      fromRoute.params.workspaceid === undefined ? fromRoute.params.qrstring : fromRoute.params.qrworkspaceid }}
    </p>
    <v-card class="elevation-12" style=" width: 100%; min-width: 500px;">
      <v-card-text class="">
        <v-form>
          <div class="py-8 px-16 text-center">
            <img src="./../assets/service-me-logo.png" style="width: 100%;" />
          </div>
          <div class="pa-4">
            <v-text-field v-model="userinfo.email" label="Email" name="login" prepend-icon="mdi-account" type="text"
              v-on:keyup="checkreturn"></v-text-field>

            <v-text-field v-model="userinfo.password" id="password" label="Password" name="password"
              prepend-icon="mdi-lock" type="password" autocomplete="on" hide-details
              v-on:keyup="checkreturn"></v-text-field>
          </div>
        </v-form>
        <div class="pa-4">
          <div class="d-flex pb-6" style="justify-content: space-between">
            <v-btn flat @click="recoverpassword()">Recover password</v-btn>
            <v-btn v-if="userinfo.email.length >= 1 || userinfo.password.length >= 1" @click="login()"
              style="background-color:#2ea3f0; color: white;">Login</v-btn>
            <v-btn v-else @click="login()">Login</v-btn>
          </div>
          <div class="pb-6" style="display: grid; grid-template-columns: 30% 40% 30%;">
            <div class="d-flex" style="align-items: center; color: whitesmoke;">
              <hr style="width: 100%;">
            </div>
            <p style="text-align: center; color: gray;">OR SIGN IN USING</p>
            <div class="d-flex" style="align-items: center; color: red;">
              <hr style="width: 100%;">
            </div>
          </div>
          <v-btn style="width: 100%;min-height: fit-content;" @click="entraIdLogin()">
            <img src="./../assets/microsoft-logo.png" style="max-height: 24px; margin: 12px 0" />
          </v-btn>
        </div>
      </v-card-text>
      <v-progress-linear :active="loading" :indeterminate="loading" height="6" absolute bottom
        color="blue accent-2"></v-progress-linear>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { HTTP } from '@/plugins/backendAPI'
import { loginRequest } from '../../auth-config';
import { mapGetters } from 'vuex';

export default {
  beforeRouteLeave(to) {
    // console.log("Leaving login route...");
    // console.log(from)
    // console.log(to.fullPath)
    // console.log(this.$store.getters.redirectRoute)
    // console.log(to.fullPath !== this.$store.getters.redirectRoute)
    if (this.$store.getters.redirectRoute !== null && to.fullPath !== this.$store.getters.redirectRoute) {
      this.$router.push({
        path: this.$store.getters.redirectRoute,
      });
    } else {
      this.$store.dispatch("setRedirectRoute", null)
    }
  },
  props: ['fromRoute'],
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  data() {
    return {
      userinfo: {
        email: '',
        password: ''
      },
      loading: false,
      prevRoute: null,
    }
  },
  async created() {
  },
  mounted() {
    this.msalInstance.clearCache()
  },
  computed: {
    ...mapGetters(["msalInstance"])
  },
  methods: {
    async entraIdLogin() {
      this.loading = true
      await this.$store.dispatch("doesLoginUseSSO", true)
      try {
        const msalResponse = await this.msalInstance.loginPopup(loginRequest);
        console.log(msalResponse)
        if (msalResponse.idTokenClaims.groups === null) {
          this.msalInstance.clearCache()
          this.$store.dispatch('showError', "Your account has not yet been activated. Please contact your organization's administrator to ensure your Microsoft account is added to the appropriate group for access to service-me.")
          this.loading = false
        } else {
          await HTTP.post('/account/login/sso', msalResponse.idTokenClaims.groups)
            .then((response) => {
              this.$store.dispatch('saveOrganizationId', response.data.organizationId);
              this.$store.dispatch('saveLegacyWorkspaceId', response.data.legacyId);
              this.$store.dispatch('saveWorkspaceId', response.data.workspaceIds.length > 0 ? response.data.workspaceIds[0] : -1);
              this.$store.dispatch('saveAuthorization', { username: response.data.email, isauthorized: true, bearertoken: HTTP.defaults.headers.common['Authorization'].substring("bearer ".length), isadministrator: response.data.isAdministrator, issuperadministrator: response.data.isSuperAdministrator });
              this.$router.push({ name: "Issues" })
              this.loading = false
            })
            .catch((error) => {
              this.msalInstance.clearCache()
              this.$store.dispatch('showError', error.response.data)
              this.loading = false
            })
        }
      } catch (error) {
        this.msalInstance.clearCache()
        this.$store.dispatch('showError', error.response.data)
        this.loading = false
      }
    },
    checkreturn(e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    login() {
      this.$store.dispatch("doesLoginUseSSO", false)
      this.loading = true
      var self = this
      var params = 'username=' + this.userinfo.email + '&password=' + encodeURIComponent(this.userinfo.password)
      delete HTTP.defaults.headers.common['Authorization']
      // eslint-disable-next-line
      axios({ method: 'post', url: process.env.VUE_APP_APIURL + '/token', data: params, headers: { 'accept': 'application/json', 'content-type': 'application/x-www-form-urlencoded' } })
        .then(response => {
          if (!response.data) {
            self.loginFailed()
          } else {
            if (HTTP.defaults.headers.common['Authorization'] === undefined) {
              HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + response.data
            }
            self.loginSuccessful(response.data)
          }
        })
        .catch(ex => {
          self.loginFailed(ex)
        })
    },
    loginSuccessful(token) {
      HTTP.post('/account/getkund', { email: this.userinfo.email })
        .then((response) => {
          if (response.data.user.role != 1) {
            this.$store.dispatch('saveOrganizationId', response.data.user.organizationId);
          } else {
            this.$store.dispatch('saveOrganizationId', response.data.user.ownerOrganizations[0].id);
          }
          this.$store.dispatch('saveLegacyWorkspaceId', response.data.legacyId);
          this.$store.dispatch('saveWorkspaceId', response.data.user.workspaceId);
          this.$store.dispatch('saveAuthorization', { username: this.userinfo.email, isauthorized: true, bearertoken: token, isadministrator: response.data.user.isAdministrator, issuperadministrator: response.data.user.isSuperAdministrator });
          this.loading = false
          this.$router.push({ name: "Issues" })
        })
        .catch(e => {
          this.$store.dispatch('showError', e.message)
          this.loading = false
        })
    },
    loginFailed() {
      this.loading = false
      this.$store.dispatch('showError', 'Check username and/or password')
      this.$store.dispatch('deleteAuthorization')
    },
    recoverpassword() {
      this.$router.push({ path: '/recoverpassword' })
    }
  }
}

</script>

<style scoped lang="css">
.additional-login-info {
  margin-bottom: 25px;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  color: lightcoral;
}
</style>
