<template>
  <div style="width: 100%; display: flex; flex-direction: column; justify-content: center;">
    <v-progress-linear v-if="loading" :active="loading" indeterminate color="blue" style=""></v-progress-linear>
    <div style="width: 100%; display: flex; justify-content: center;">
      <div style="width: 100% ;max-width: 250px; display: flex; flex-direction: column;">
        <div class="infotext text-center mb-4">
          <p v-html="getInfoText"></p>
        </div>
        <div class="mb-4">
          <v-form ref="contactform" v-model="isFormValid">
            <div class="mb-4">
              <v-text-field v-model="senderName" class="messagearea smalltext" type="text" outlined hide-details
                label="Name"></v-text-field>
            </div>
            <div>
              <v-text-field validate-on-blur required :rules="emailRules" v-model="senderEmail"
                class="messagearea smalltext" type="email" outlined label="* Email"></v-text-field>
            </div>
            <div class="mb-4">
              <v-text-field v-model="senderPhone" class="messagearea smalltext" type="text" outlined hide-details
                label="Phone"></v-text-field>
            </div>
            <div>
              <v-textarea required :rules="requiredRules" v-model="senderMessage" class="messagearea" outlined
                label="* Message"></v-textarea>
            </div>
          </v-form>
        </div>
        <div class="mb-4" style="display: flex; justify-content: space-between">
          <v-btn class="smallbutton" @mousedown="$router.go(-1)" :loading="sending" :disabled="sending">Back</v-btn>
          <v-btn class="smallbutton ml-3" @mousedown="save()" :loading="sending" :disabled="!isFormValid">Send
            <template v-slot:loader>
              <span>Sending...</span>
            </template>
          </v-btn>
        </div>
      </div>
    </div>
  </div>

</template>


<script>

import { HTTP } from '@/plugins/backendAPI'

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export default {
  props: ['formtype', 'workspaceid', 'qrworkspaceid', 'qrname'],
  data() {
    return {
      loading: false,
      sending: false,

      senderName: null,
      senderEmail: null,
      senderPhone: null,
      senderMessage: null,

      requiredRules: [
        v => !!v || 'Field is required',
      ],
      emailRules: [
        v => !!v || 'Field is required',
        v => !!emailRegex.test(v) || 'Email is in incorrect format',
      ],
      isFormValid: false,
    }
  },
  methods: {
    save() {
      this.sending = true
      HTTP.post('/mobil/sendform', {
        workspaceid: this.workspaceid,
        qrworkspaceid: this.qrworkspaceid,
        formType: this.getFormType,
        name: this.senderName,
        email: this.senderEmail,
        phone: this.senderPhone,
        message: this.senderMessage,
      }).then(() => {
        setTimeout(() => {
          this.loading = false
          this.sending = false
        }, 100);
        if (isNaN(this.$route.fullPath.split("/")[3])) {
          this.$router.push({ name: 'MobileLegacyFormSuccess' });
        } else {
          this.$router.push({ name: 'MobileFormSuccess' });
        }
      }).catch(e => {
        setTimeout(() => {
          this.loading = false
          this.sending = false
        }, 100);
        this.$store.dispatch('showError', e.response.data)
      })
    },
  },
  computed: {
    getFormType() {
      if (this.formtype === 'contactrequest') {
        return 'Contact request'
      } else {
        return 'Feedback'
      }
    },
    getInfoText() {
      if (this.formtype === 'contactrequest') {
        return 'Fill out the form and we will<br/>contact you as soon as possible'
      } else {
        return 'Fill out the form and we will<br/>review your feedback as soon as possible'
      }
    }
  }
}
</script>


<style></style>
