// EXEMPEL på object
// let restrictions = [];
// restrictions.push(
//   { type: "Starts with", description: "se" },
//   { type: "AND", description: "" },
//   { type: "Contains", description: "something" },
//   { type: "OR", description: "" },
//   { type: "Starts with", description: "no" },
//   { type: "OR", description: "" },
//   { type: "Starts with", description: "se" },
//   { type: "AND", description: "" },
//   { type: "Ends with", description: "abb.com" }
// );

export function EmailRegexBuilder(restrictions) {
  let regexParts = [];
  let newRegexBlock = true;
  const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  for (let index = 0; index < restrictions.length; index++) {
    const restriction = restrictions[index];
    const restrictionType = restriction.type;
    const restrictionValue = escapeRegex(restriction.description).toLowerCase();
    // console.log(restriction);

    if (restrictionType === "Starts with") {
      // console.log("Added: Starts with");
      if (newRegexBlock) {
        regexParts.push(`(^${restrictionValue}.*`);
        newRegexBlock = false;
      } else {
        regexParts[regexParts.length - 1] += `^${restrictionValue}.*`;
      }
    } else if (restrictionType === "Contains") {
      // console.log("Added: Contains");
      if (newRegexBlock) {
        regexParts.push(`((?=.*${restrictionValue})`);
        newRegexBlock = false;
      } else {
        // console.log(regexParts[regexParts.length - 1]);
        // console.log(index);
        regexParts[regexParts.length - 1] += `(?=.*${restrictionValue})`;
      }
    } else if (restrictionType === "Ends with") {
      // console.log("Added: Ends with");
      if (newRegexBlock) {
        regexParts.push(`(${restrictionValue}$`);
        newRegexBlock = false;
      } else if (restrictions[index - 2].type === "Starts with") {
        regexParts[regexParts.length - 1] += `${restrictionValue}$`;
      } else {
        regexParts[regexParts.length - 1] += `.*${restrictionValue}$`;
      }
    } else if (restrictionType === "OR") {
      regexParts[regexParts.length - 1] += `)`;
      newRegexBlock = true;
    }
  }

  regexParts[regexParts.length - 1] += `)`;
  console.log(regexParts);
  const pattern = regexParts.join("|");
  console.log(pattern);
  return pattern;
}
// (^Max.*(?=.*Dahlberg)(mindflower\.se)$)|((^David.*)(abb\.com)$)
export function EmailRegexObjectBuilder(emailRegex) {
  let objectParts = [];
  const unescapeRegex = (str) => {
    return str.replace(/\\([.*+?^${}()|[\]\\])/g, "$1");
  };
  console.log(emailRegex);
  const regexBlocks = emailRegex.split("|");
  for (let index = 0; index < regexBlocks.length; index++) {
    let regexParticles;
    const regexBlock = regexBlocks[index];
    const regexStartParticle = regexBlock.split(".*(");

    if (regexStartParticle.length > 1) {
      let regexEndParticle = regexStartParticle[1].split(").*");
      if (regexEndParticle.length > 1) {
        const regexContainsParticles = regexEndParticle[0].split(")(");
        regexStartParticle.pop();
        regexEndParticle = [regexEndParticle.pop()];
        regexParticles = regexStartParticle.concat(regexContainsParticles).concat(regexEndParticle);
      } else {
        const regexContainsParticles = regexEndParticle[0].split(")(");
        regexStartParticle.pop();
        regexParticles = regexStartParticle.concat(regexContainsParticles);
      }
    } else {
      let regexContainsAndEndParticle = regexBlock.split(").*");
      if (regexContainsAndEndParticle.length > 1) {
        const regexContainsParticles = regexContainsAndEndParticle[0].split(")(");
        const regexEndParticle = regexContainsAndEndParticle[1];
        regexParticles = regexContainsParticles.concat(regexEndParticle);
      } else {
        const regexContainsParticles = regexBlock.split(")(");
        console.log(regexBlock);
        if (regexContainsParticles.length <= 1 && regexBlock.startsWith("(^") && regexBlock.endsWith("$)")) {
          regexParticles = regexBlock.split(".*");
        } else {
          regexParticles = regexContainsParticles;
        }
      }
    }

    for (let index = 0; index < regexParticles.length; index++) {
      const regexParticle = regexParticles[index];
      console.log(regexParticle);
      if (regexParticle.startsWith("(^")) {
        if (regexParticles[regexParticles.length - 1] === regexParticle) {
          const startsWithDescription = unescapeRegex(regexParticle.substring(2, regexParticle.length - 3));
          objectParts.push({ type: "Starts with", description: startsWithDescription, isEditable: false });
        } else {
          const startsWithDescription = unescapeRegex(regexParticle.substring(2));
          objectParts.push({ type: "Starts with", description: startsWithDescription, isEditable: false });
        }
      }
      // Contains
      if (regexParticle.startsWith("((?=.*") || regexParticle.startsWith("?=.*")) {
        if (regexParticles[0] === regexParticle && regexParticles[regexParticles.length - 1] === regexParticle) {
          const containsDescription = unescapeRegex(regexParticle.substring(6, regexParticle.length - 2));
          objectParts.push({ type: "Contains", description: containsDescription, isEditable: false });
        } else if (regexParticles[0] === regexParticle) {
          const containsDescription = unescapeRegex(regexParticle.substring(6));
          objectParts.push({ type: "Contains", description: containsDescription, isEditable: false });
        } else if (regexParticles[regexParticles.length - 1] === regexParticle) {
          const containsDescription = unescapeRegex(regexParticle.substring(4, regexParticle.length - 2));
          objectParts.push({ type: "Contains", description: containsDescription, isEditable: false });
        } else {
          const containsDescription = unescapeRegex(regexParticle.substring(4));
          objectParts.push({ type: "Contains", description: containsDescription, isEditable: false });
        }
      }
      // Ends with
      if (regexParticle.endsWith("$)")) {
        if (regexParticles[0] === regexParticle) {
          const endsWithDescription = unescapeRegex(regexParticle.substring(1, regexParticle.length - 2));
          objectParts.push({ type: "Ends with", description: endsWithDescription, isEditable: false });
        } else {
          const endsWithDescription = unescapeRegex(regexParticle.substring(0, regexParticle.length - 2));
          objectParts.push({ type: "Ends with", description: endsWithDescription, isEditable: false });
        }
      }
      if (regexParticles.length > 1 && regexParticles.length - 1 !== index) {
        objectParts.push({ type: "AND", description: "", isEditable: false });
      }
    }
    if (regexBlocks.length > 1 && regexBlocks.length - 1 !== index) {
      objectParts.push({ type: "OR", description: "", isEditable: false });
    }
  }
  if (objectParts.length > 1) {
    objectParts[objectParts.length - 2].isEditable = true;
    objectParts[objectParts.length - 1].isEditable = true;
  } else {
    objectParts[objectParts.length - 1].isEditable = true;
  }
  return objectParts;
}
