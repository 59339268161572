<template>
  <div class="adminbackground">
    <AutoLogout></AutoLogout>
    <AppBar></AppBar>
    <router-view></router-view>
  </div>
</template>

<script>
import AutoLogout from "../../components/AutoLogout.vue";
import { RouterView } from "vue-router";
import AppBar from "../../components/AppBarMain.vue";

export default {
  components: { AppBar, AutoLogout, RouterView },
};
</script>
