<template>
  <div class="adminbackground">
    <div class="map-area-mapview-100">
      <MapComponent v-if="loadMap" :qrId="qrCodeId" :name="qrCodeMapInfo.mapImageName" :marker-draggable="false"
        :qrs="[{ qrId: qrCodeId, qrString: qrCodeMapInfo.qrIndex, qrDescription: qrCodeMapInfo.qrDescription, latLng: qrCodeMapInfo.latLng }]" />
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import MapComponent from '../components/MapComponent.vue'

export default {
  name: 'MapPage',
  components: {
    MapComponent,
  },
  data() {
    return {
      loadMap: false,
      displayErrorText: false,
      pictureName: null,
      qrCodeMapInfo: null,
      qrCodeId: 0,
    }
  },
  created() {
    this.getQrInformation(this.$route.params.qrCodeId)
  },
  methods: {
    getQrInformation(qrId) {
      this.qrCodeId = Number(this.$route.params.qrCodeId)
      HTTP.get('/qr/get/map-info/' + qrId).then(response => {
        this.qrCodeMapInfo = response.data
        this.loadMap = true
      }).catch((e) => {
        this.loadMap = false
        if (e.response.status === 401 && e.response.data === "") {
          this.$store.dispatch('showError', 'Error when fetching map info: ' + 'You are not authorized for this request')
        } else {
          this.$store.dispatch('showError', 'Error when fetching map info: ' + e.response.data)
        }
      })
    },
  }
}
</script>
