<template>
  <div class="appbackground d-flex" style="flex-direction: column; align-items: center;">
    <div style="margin-top: 12px;">
      <img src="./../assets/service-me-logo.png" style="width: 150px;">
    </div>
    <div
      style="display: flex; flex-direction: column; height: 100%; align-items: center; min-width: 270px; max-width: 500px; margin: 10% 0 0 0; width: 100%;">
      <div v-if="this.logoImage != null && this.logoImage != 'default-logo'"
        style="display:flex;justify-content:center; align-items: center;height: 70px; width: 270px; margin-bottom: 12px">
        <img :src="`${backendurl}/shared/logo-images/${this.logoImage}`" style="max-height: 70px; max-width: 270px;"
          height="auto">
      </div>
      <div v-if="this.logoImage == 'default-logo'"
        style="display:flex;justify-content:center; align-items: end; height: 70px; width: 270px; margin-bottom: 12px">
        <img src="./../assets/service-me-logo.png" style="max-height: 70px; max-width: 270px;" height="auto">
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex';

export default {
  props: ['qrstring', 'workspaceid', 'qrworkspaceid'],
  data() {
    return {
      contactRequest: 'Contact request',
      feedback: 'Feedback',
      inventorytypes: [],
      isFormsActive: {},
      location: null,
      organization: null,
      logoImage: null,
      // eslint-disable-next-line no-undef
      backendurl: process.env.VUE_APP_APIURL,
      qrName: null,
      workspaceId: null,
      qrWorkspaceId: null,
    }
  },
  computed: {
    ...mapGetters([
      "ismobileauthorized",
    ])
  },
  created() {
    this.$store.dispatch('saveLegacyMobileParams', this.qrstring)
    this.$store.dispatch('saveMobileParams', [this.workspaceid, this.qrworkspaceid])
    HTTP.get(
      `/mobil/checkifqrexists/${this.qrstring}/${this.workspaceid}/${this.qrworkspaceid}`
    )
      .then(async (response) => {
        await this.$store.dispatch("saveQrCodeStatus", response.data.isFound);
        await this.$store.dispatch("doesOrganizationUseForms", response.data.useForms);
        if (response.data.isUsePinCode) {
          await this.$store.dispatch("usePinCodeForMobileAuth");
        } else {
          await this.$store.dispatch("dontUsePinCodeForMobileAuth");
        }
      })
      .catch(async (err) => {
        console.log(err);
        await this.$store.dispatch("showError", err.response.data);
      });
    if (this.qrstring != undefined) {
      HTTP.get(`/mobil/get/logo/legacy/${this.qrstring}`).then((response) => {
        this.logoImage = response.data
      }).catch(() => {
        this.logoImage = "default-logo"
      })
    } else if (this.workspaceid != undefined && this.qrworkspaceid != undefined) {
      HTTP.get(`/mobil/get/logo/${this.workspaceid}`).then((response) => {
        this.logoImage = response.data
      }).catch(() => {
        this.logoImage = "default-logo"
      })
    }
  },
}
</script>

<style>
.edit-qr {
  display: inline-block;
  font-size: 18px;
  text-decoration: underline;
  margin-top: 120px;
  cursor: pointer;
  color: lightgray;
}

.edit-qr:hover {
  color: lightskyblue;
}

.btn-report-container {
  position: relative;
}

.btn-wrap-txt {
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  width: 180px;
  max-height: 60px;
  margin: 0;
}

.active-report-icon {
  position: absolute;
  z-index: 50;
  right: -8px;
  top: -5px;
  height: 35px;
}
</style>
