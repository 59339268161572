<template>
  <div class="d-flex justify-center login-recover-window" style="margin: 10vh auto;">
    <v-card class="elevation-12" style=" width: 100%; min-width: 20vw;">
      <v-card-text class="">
        <v-form onsubmit="return false;" v-model="isFormValid" ref="emailForm">
          <div class="py-8 text-center"><img src="./../assets/service-me-logo.png" class="biglogo" /></div>
          <v-text-field v-model="email" label="Email address" prepend-icon="mdi-account" :rules="reqRules" required
            v-on:keyup="checkreturn"></v-text-field>
          <!-- This text field prevents default form behavior, where the page is reloaded when pressing enter in a form that has only one text field -->
          <v-text-field style="display: none;"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="login-actions  pa-4" style="display: flex; justify-content: space-between;">
        <v-btn text @click="$router.go(-1)">Back</v-btn>
        <v-btn :disabled="email === ''" color="primary adminlargerecoverbutton" @click="sendEmail()">Recover
          password</v-btn>
      </v-card-actions>
      <v-alert v-if="info" type="success">{{ info }}</v-alert>
      <v-progress-linear :active="loading" :indeterminate="loading" height="6" absolute bottom
        color="blue accent-2"></v-progress-linear>
    </v-card>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'

export default {
  data() {
    return {
      loading: false,
      email: '',
      info: '',
      isFormValid: false,
      reqRules: [
        v => !!v || 'Field is required',
      ],
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    checkreturn(e) {
      // if (e.keyCode === 13) {
      //   this.sendEmail()
      // }
    },
    sendEmail() {
      HTTP.post('/token/recoverpassword', 'email=' + this.email).then(() => {
        this.loading = false
        this.info = 'Email sent with password recovery details.'
      }).catch(e => {
        this.$store.dispatch('showError', e.response.data)
      })
    }
  }
}

</script>

<style lang="css" scoped></style>
