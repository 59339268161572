import HTTP from "../plugins/backendAPI";
import store from "../store";
import router from "../router";

export async function logout() {
  console.log("User is being logged out...");
  HTTP.defaults.headers.common["Authorization"] = null;
  await store.getters.msalInstance.clearCache();
  await store.dispatch("deleteAuthorization");
  await router.push({ name: "Login" });
  await store.dispatch("clearOrganizationitems");
  await store.dispatch("clearWorkspaceitems");
  await store.dispatch("saveWorkspaceId", -1);
  await store.dispatch("saveOrganizationId", -1);
  await store.dispatch("doesLoginUseSSO", false);
}
