<template>
  <div style="padding: 0 32px; width: 100%;">
    <v-progress-linear v-if="loading" :active="loading" indeterminate color="blue"
      style="margin-top: 12px;"></v-progress-linear>
    <div class="text-center" v-if="inventorytypes.length > 0">
      <div class="infotext text-center mb-5">Select the item you want to report</div>
      <v-row justify="center" v-for="item in inventorytypes" v-bind:key="item.id">
        <div class="btn-report-container">
          <div class="active-report-icon">
            <v-icon large v-if="item.activeReport" class="reported-alert">mdi-alert-circle</v-icon>
          </div>
          <v-btn class="widebutton my-2" @mousedown="goToReport(item.id)">
            {{ item.description }}
          </v-btn>
        </div>
      </v-row>
    </div>
    <div class="text-center"
      v-if="useForms && (isFormsActive.isContactRequestActive || isFormsActive.isFeedbackActive)">
      <div class="infotext text-center mb-5 mt-10">Forms</div>
      <v-row justify="center" v-if="isFormsActive.isContactRequestActive">
        <v-btn class="widebutton my-2" @mousedown="goToForm('contactrequest')">{{ contactRequest }}</v-btn>
      </v-row>
      <v-row justify="center" v-if="isFormsActive.isFeedbackActive">
        <v-btn class="widebutton my-2" @mousedown="goToForm('feedback')">{{ feedback }}</v-btn>
      </v-row>
    </div>
    <div class="text-center" v-if="inventorytypes.length > 0">
      <p class="edit-qr" @click="goToEditMode">Edit</p>
    </div>
    <div v-if="felmeddelande">
      <div class="text-center mb-5">
        <i class="buttonicon far fa-thumbs-up mb-5"></i><br />
        Sorry, something went wrong. <br />
        You might have scanned a code that dont exists in our system<br />
        <br />
        <br />
        {{ felmeddelande }}
      </div>
    </div>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex';

export default {
  props: ['qrstring', 'workspaceid', 'qrworkspaceid'],
  data() {
    return {
      contactRequest: 'Contact request',
      feedback: 'Feedback',
      inventorytypes: [],
      isFormsActive: {},
      felmeddelande: null,
      location: null,
      loading: false,
      qrName: null,
      workspaceId: null,
      qrWorkspaceId: null,
    }
  },
  watch: {
    pinCode(newPinCode) {
      if (newPinCode != "") {
        var pinArray = newPinCode.split("")
        if (pinArray.length === 4) {
          this.checkPinCode(newPinCode);
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      "useForms"
    ]),
  },
  created() {
    this.loading = true
    var self = this;
    HTTP.get(`/mobil/checkifqrexists/${self.qrstring}/${self.workspaceid}/${self.qrworkspaceid}`).then(response => {
      if (!response.data.isFound) {
        if (response.data.workspaceId > 0) {
          this.$router.push({ name: 'MobileEdit', params: { workspaceid: response.data.workspaceId, qrworkspaceid: this.qrworkspaceid } }).catch(() => { });
        } else if (response.data.legacyWorkspaceId > 0) {
          this.$router.push({ name: 'MobileLegacyEdit', params: { qrstring: this.qrstring } }).catch(() => { });
        }
        else {
          this.$store.dispatch('showError', 'The qr code you are trying to access does not have a workspace that exists in our system.')
        }
      }
      else {
        self.workspaceId = response.data.workspaceId;
        self.qrWorkspaceId = response.data.qrWorkspaceId;
        HTTP.get(`/mobil/getcontactforms/${self.workspaceId}/${self.qrWorkspaceId}`).then(response => {
          self.qrName = response.data.qrname
          self.inventorytypes = response.data.inventorytypes
          self.isFormsActive = response.data.form
          setTimeout(() => {
            this.loading = false
          }, 100)
        })
      }
    }).catch((err) => {
      this.felmeddelande = err.response.data
    })
  },
  methods: {
    goToEditMode() {
      if (this.qrstring !== undefined) {
        this.$router.push({ name: 'MobileLegacyEdit', params: { qrstring: this.qrstring, } }).catch(() => { });
      } else if (this.workspaceid !== undefined && this.qrworkspaceid !== undefined) {
        this.$router.push({ name: 'MobileEdit', params: { workspaceid: this.workspaceId, qrworkspaceid: this.qrWorkspaceId, } }).catch(() => { });
      }
    },
    goToReport(item) {
      if (this.qrstring != undefined) {
        this.$router.push({ name: 'MobileLegacyReport', params: { qrdetailid: item, qrstring: this.qrstring, } }).catch(() => { });
      } else if (this.workspaceid != undefined && this.qrworkspaceid != undefined) {
        this.$router.push({ name: 'MobileReport', params: { qrdetailid: item, workspaceid: this.workspaceId, qrworkspaceid: this.qrWorkspaceId } }).catch(() => { });
      }
    },
    goToForm(formType) {
      if (this.qrstring != undefined) {
        this.$router.push({ name: 'MobileLegacyForm', params: { formtype: formType, workspaceid: this.workspaceId, qrworkspaceid: this.qrWorkspaceId, qrname: this.qrName } }).catch(() => { });
      } else if (this.workspaceid != undefined && this.qrworkspaceid != undefined) {
        this.$router.push({ name: 'MobileForm', params: { formtype: formType, workspaceid: this.workspaceId, qrworkspaceid: this.qrWorkspaceId, qrname: this.qrName } }).catch(() => { });
      }
    },
  }
}


</script>

<style>
.edit-qr {
  display: inline-block;
  font-size: 18px;
  text-decoration: underline;
  margin-top: 120px;
  cursor: pointer;
  color: lightgray;
}

.edit-qr:hover {
  color: lightskyblue;
}

.btn-report-container {
  position: relative;
}

.btn-wrap-txt {
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  width: 180px;
  max-height: 60px;
  margin: 0;
}

.active-report-icon {
  position: absolute;
  z-index: 50;
  right: -8px;
  top: -5px;
  height: 35px;
}
</style>
