function defaultDataTableFilter(value, search) {
  if (!search) {
    return true;
  } else if (!value) {
    return false;
  }
  search = search.toString().toLowerCase();
  value = value.toString().toLowerCase();
  return value.includes(search);
}

export default defaultDataTableFilter;
