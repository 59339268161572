<template>
  <div>
    <div style="width: 270px; display: flex; flex-direction: column; align-items: center;">
      <div class="text-center mb-5 mt-10">QR Code was successfully {{ this.$route.query.operation === "created" ?
        "created!" : "edited!" }}
      </div>
      <v-row justify="center"><i class="buttonicon far fa-thumbs-up"></i></v-row>
      <div style="display: flex; justify-content: center; width: 100%;">
        <v-btn class="widebutton" style=" width: 250px; margin: 38px 10px 32px 10px"
          @mousedown="goBackToSelect">Back</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
  },
  data() {
    return {}
  },
  methods: {
    goBackToSelect() {
      if (this.$route.params.qrstring != undefined) {
        this.$router.push({ name: "MobileSelectLegacy", params: { qrstring: this.$route.params.qrstring } })
      }
      if (this.$route.params.workspaceid != undefined && this.$route.params.qrworkspaceid != undefined) {
        this.$router.push({ name: "MobileSelect", params: { workspaceid: this.$route.params.workspaceid, qrworkspaceid: this.$route.params.qrworkspaceid } })
      }
    }
  }
}
</script>
