<template>
  <v-card style="width: 75vw; margin: 32px auto">
    <div class="d-flex align-start" style="padding: 24px;">
      <h2 class="mr-auto">Organization Users</h2>
      <ExportButton v-if="isadministrator || issuperadministrator" :table-items="tableItems" :table-type="'users'"
        :workspaces="workspaceFilterOptions" :export-message="'Export List'"></ExportButton>
      <Tooltip :message="tooltipMessage"></Tooltip>
    </div>
    <v-row class="d-flex align-center mx-5 mb-3">
      <v-col cols=8>
        <v-text-field v-model="search" label="Search" hide-details clearable></v-text-field>
      </v-col>
      <v-col cols=3>
        <v-btn color="white" @click="newitem()"><v-icon medium class="py-2 mr-2">mdi-plus</v-icon> New</v-btn>
      </v-col>
    </v-row>
    <v-data-table dense :search="search" :headers="headers" :items="tableItems" :sort-by.sync="sortOptions.sortBy"
      :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="20"
      :custom-key-sort="Object.assign({}, customSortForOrganization, customSortForWorkspaces)"
      :custom-filter="customFilterForUsers" :footer-props="footerProps" ref="users_data_table" class="elevation-1">
      <template v-slot:item="{ item }">
        <tr @click="editItem(item)" class="table-hover"
          :class="{ 'last-selected-row': idOfLastSelectedRow === item.id }">
          <td>{{ item.firstname }}</td>
          <td>{{ item.lastname }}</td>
          <td>{{ item.email }}</td>
          <td>
            <div v-if="item.role === 0" style="padding: 10px 0;">
              <p>
                {{
                  mapOrganizationIdToOrganizationObject(item.organizationId)
                }}
              </p>
            </div>
            <div v-else-if="item.role === 1" style="display: flex; gap: 10px; flex-wrap: wrap; padding: 10px 0;">
              <p v-for="(organization, index) in item.ownerOrganizations" :key="index">
                {{ organization.name }}
              </p>
            </div>
          </td>
          <td>
            <div v-if="item.role === 0 && !item.isAdministrator"
              style="display: flex; gap: 10px; flex-wrap: wrap; padding: 10px 0;">
              <p v-for="(workspace, index) in mapWorkspaceIdsToWorkspaceObjects(item.workspaceIds)" :key="index">
                {{ workspace.name }}
              </p>
            </div>
            <div v-else-if="item.role === 1 && !item.isAdministrator"
              style="display: flex; gap: 10px; flex-wrap: wrap; padding: 10px 0;">
              <div v-for="(workspace, index) in this.workspaceFilterOptions" :key="index">
                <p v-if="item.ownerOrganizations.some(oo => oo.id === workspace.organizationId)">
                  {{ workspace.name }}
                </p>
              </div>
            </div>
            <div v-else style="padding: 10px 0;">
              All
            </div>
          </td>
          <td>
            <v-icon color="green-darken-1" hide-details v-if="item.role === 1"> mdi-check </v-icon>
          </td>
          <td>
            <v-icon color="green-darken-1" hide-details v-if="item.isAdministrator"> mdi-check </v-icon>
          </td>
          <td>
            <div style="display: flex; justify-content: center;">
              <v-tooltip v-if="item.role === 1" text="Owner can't be deleted" location="top">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="grey-darken-1" size="30px" class="pa-2" style="opacity: 0.38;"
                    @click.stop>
                    mdi-delete </v-icon>
                </template>
              </v-tooltip>
              <v-icon v-else color="grey-darken-1" size="30px" class="pa-2" @click.stop="deleteItem(item)">
                mdi-delete </v-icon>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline"> {{ this.editedItem.id === 0 ? 'New' : 'Edit' }} - User</span>
        </v-card-title>
        <v-card-text>
          <v-col v-if="editedItem.role === 1" cols="12">
            <v-alert type="error" style="padding: 12px 12px 12px 12px; width: 100%; z-index: 1;">Contact support to edit
              owner user information.</v-alert>
          </v-col>
          <v-container>
            <v-form v-model="isFormValid" ref="userform">
              <v-row>
                <v-col>
                  <v-text-field :disabled="editedItem.isSSOUser || editedItem.role === 1" required :rules="reqRules"
                    ref="firstName" v-model="editedItem.firstname" label="First name"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :disabled="editedItem.isSSOUser || editedItem.role === 1" required :rules="reqRules"
                    v-model="editedItem.lastname" label="Last name"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :disabled="editedItem.isSSOUser || editedItem.role === 1"
                    :rules="editedItem.isSSOUser ? reqRules : emailRules" required validate-on-blur
                    v-model="editedItem.email" label="User name / e-mail"></v-text-field>
                </v-col>
                <v-col v-if="editedItem.id === 0 && !editedItem.isSSOUser">
                  <!-- <v-text-field v-model="editedItem.lastname" label="Password"></v-text-field> -->
                  <v-text-field v-model="editedItem.password" :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="newItem ? newPassRules : editPassRules" :type="showpassword ? 'text' : 'password'"
                    label="Password" hint="At least 15 characters, 1 digit, 1 special character" counter
                    @click:append="showpassword = !showpassword" autocomplete="off"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select :disabled="editedItem.role === 1 || editedItem.isAdministrator"
                    v-model="editedItem.workspaceIds"
                    :items="workspaceFilterOptions.filter(w => editedItem.role === 1 ? editedItem.ownerOrganizations.some(oo => oo.id === w.organizationId) : w.organizationId === editedItem.organizationId)"
                    item-value="id" item-title="name" label="Workspaces" multiple hint="Select workspaces"
                    persistent-hint></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox :disabled="editedItem.role === 1" class="d-inline-flex"
                    v-model="editedItem.isAdministrator"
                    @update:model-value="(value) => { value ? editedItem.workspaceIds = workspaceFilterOptions.filter(w => w.organizationId === editedItem.organizationId).map(w => w.id) : editedItem.workspaceIds }"
                    label="Administrator"></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox class="d-inline-flex" v-model="editedItem.sendReport"
                    label="Send workspace reports to the user"></v-checkbox>
                </v-col>
                <v-col v-if="editedItem.sendReport" cols="12">
                  <v-select v-model="editedItem.reportTimeInterval" :items="[{
                    TimeInterval: 'Monthly', TimeIntervalId
                      : 0
                  }, {
                    TimeInterval: 'Weekly', TimeIntervalId
                      : 1
                  }]" item-value="TimeIntervalId" item-title="TimeInterval" label="Time interval"
                    hint="Select how often reports should be sent to the user (Default = Monthly)"
                    persistent-hint></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="save()" :disabled="!isFormValid"><v-icon small
              class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
// import { EventBus } from '@/store/event-bus.js'
import Tooltip from '../../components/HelpTooltip.vue';
import ExportButton from '../../components/ExportButton.vue'
import defaultDataTableFilter from '../../helpers/defaultFilterForDataTables';

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

// (?=.*\d) ensures at least one digit exists
// (?=.*[^\w\såäöæøÅÄÖÆØ]) ensures at least one special character exists, excluding Nordic letters without duplication.
// .{15,} ensures at least 15 characters of any type
const passWdRegex = /^(?=.*\d)(?=.*[^\w\såäöæøÅÄÖÆØ]).{15,}$/;

export default {
  components: { Tooltip, ExportButton, },
  data() {
    return {
      dialog: false,
      enableEscClear: true,
      search: '',
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      headers: [
        { title: 'First name', key: 'firstname', maxWidth: "200px", minWidth: "80px", nowrap: true },
        { title: 'Last name', key: 'lastname', maxWidth: "200px", minWidth: "80px", nowrap: true },
        { title: 'E-mail', key: 'email', minWidth: "300px", maxWidth: "500px", nowrap: true },
        { title: 'Organization', key: 'organizationId', minWidth: "100px", nowrap: true },
        { title: 'Workspaces', key: 'workspaceIds', nowrap: true },
        { title: 'Owner', key: 'role', width: 20, align: "center" },
        { title: 'Admin', key: 'isAdministrator', width: 20, align: "center" },
        { title: 'Edit', key: 'action', sortable: false, width: 120 }
      ],
      customSortForOrganization: {
        organizationId: (d1, d2) => {
          try {
            if (d1 === null) {
              return 1
            }
            if (d2 === null) {
              return -1
            }
            if (d1 < d2) {
              return -1
            }
            return 1
          } catch (e) {
            return 1
          }
        }
      },
      customSortForWorkspaces: {
        workspaceIds: (d1, d2) => {
          try {
            if (d1.length < d2.length) {
              return -1
            }
            return 1
          } catch (e) {
            return 1
          }
        }

      },
      editedItem: {},
      tableItems: [],
      workspaceFilterOptions: this.workspaceitems,
      organizationFilterOptions: this.organizationitems,
      newPassRules: [
        v => !!v || 'Field is required',
        v => !!passWdRegex.test(v) || 'At least 15 characters, 1 digit, 1 special character',
      ],
      editPassRules: [
        v => (!!v === false || v !== null && !!passWdRegex.test(v)) || 'At least 15 characters, 1 digit, 1 special character',
      ],
      reqRules: [
        v => !!v || 'Field is required',
      ],
      emailRules: [
        v => !!v || 'Field is required',
        v => !!emailRegex.test(v) || 'Email is in incorrect format',
      ],
      isFormValid: false,
      showpassword: false,
      newItem: false,
      idOfLastSelectedRow: 0,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      tooltipMessage: [
        "Users",
        'Add, edit, or remove your administrators here. These are the staff members who will handle active issues. Administrators are later connected to your inventory types to receive reports from the system.',
      ],
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'organizationid',
      'organizationitems',
      'workspaceitems',
      "isadministrator",
      "issuperadministrator",
    ]),
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.userSort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    },
    organizationid(newValue) {
      this.getitems(newValue);
      this.getAllWorkspacesForDataTableFiltering();
      this.getAllOrganizationsForDataTableFiltering();
    },
    dialog(val) {
      if (val) this.enableEscClear = false
      if (!val) {
        setTimeout(() => {
          this.enableEscClear = true
        }, 100)
      }
    },
  },
  created() {
    this.getitems(this.organizationid);
    this.getAllWorkspacesForDataTableFiltering();
    this.getAllOrganizationsForDataTableFiltering();
    this.sortOptions = this.sort.userSort || this.sortOptions;
  },
  mounted() {
    document.addEventListener('keydown', this.clearSearchEsc)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.clearSearchEsc)
  },
  methods: {
    // convertWorkspaceIdsToString(workspaceIds) {
    //   if (workspaceIds === undefined || workspaceIds === null || this.workspaceFilterOptions === undefined) {
    //     return []
    //   }
    //   return this.workspaceFilterOptions.filter(wItem => workspaceIds.find(wId => wId === wItem.id)).map(w => w.name).join("; ")
    // },
    mapWorkspaceIdsToWorkspaceObjects(workspaceIds) {
      if (workspaceIds === undefined || workspaceIds === null || this.workspaceFilterOptions === undefined) {
        return []
      }
      return this.workspaceFilterOptions.filter(wItem => workspaceIds.find(wId => wId === wItem.id))
    },
    mapOrganizationIdToOrganizationObject(organizationId) {
      if (this.organizationFilterOptions === undefined && this.organizationFilterOptions.constructor !== Array) {
        return ""
      }
      const organization = this.organizationFilterOptions.find(orgItem => orgItem.id === organizationId)
      return organization != null ? organization.name : ""
    },
    customFilterForUsers(value, search, item) {
      const resultDefault = defaultDataTableFilter(value, search)
      if (Number(this.organizationid) === 2) {
        const resultUsersByWorkspace = this.filterUsersByWorkspace(value, search, item)
        const resultUsersByOrganization = this.filterUsersByOrganization(value, search, item)
        return resultDefault || resultUsersByWorkspace || resultUsersByOrganization;
      } else {
        const resultUsersByWorkspace = this.filterUsersByWorkspace(value, search, item)
        return resultDefault || resultUsersByWorkspace;
      }
    },
    filterUsersByWorkspace(value, search, item) {
      if (!search || value != item.raw.email || !value) {
        return false
      }
      search = search.toString().toLowerCase()
      const sortedWorkspaceItemByUserAndSearch = this.workspaceFilterOptions.filter(wItem => item.raw.workspaceIds.find(wId => wId === wItem.id))
      if (sortedWorkspaceItemByUserAndSearch !== undefined) {
        return sortedWorkspaceItemByUserAndSearch.some(w => w.name.toLowerCase().includes(search))
      } else {
        return false
      }
    },
    filterUsersByOrganization(value, search, item) {
      if (!search || value != item.raw.email || !value) {
        return false
      }
      search = search.toString().toLowerCase()
      let sortedWorkspaceItemByUserAndSearch;
      if (item.raw.organizationId !== null) {
        sortedWorkspaceItemByUserAndSearch = this.organizationitems.filter(oItem => oItem.id === item.raw.organizationId)
      } else {
        sortedWorkspaceItemByUserAndSearch = this.organizationitems.filter(oItem => item.raw.ownerOrganizations.find(oo => oo.id === oItem.id))
      }
      if (sortedWorkspaceItemByUserAndSearch !== undefined) {
        return sortedWorkspaceItemByUserAndSearch.some(w => w.name.toLowerCase().includes(search))
      } else {
        return false
      }
    },
    getitems(organizationId) {
      if (organizationId >= 1) {
        HTTP.get('/users/get/' + organizationId).then(response => {
          this.tableItems = response.data
          this.allTableItems = response.data
        })
      }
    },
    getAllWorkspacesForDataTableFiltering() {
      if (this.organizationid === 2) {
        HTTP.get('/workspace/get/filter-options').then(response => {
          this.workspaceFilterOptions = response.data
        }).catch(() => {
          this.workspaceFilterOptions = []
        })
      } else {
        HTTP.get(`/workspace/get/filter-options?organization-id=${this.organizationid}`).then(response => {
          this.workspaceFilterOptions = response.data
        }).catch(() => {
          this.workspaceFilterOptions = []
        })
      }
    },
    getAllOrganizationsForDataTableFiltering() {
      if (this.organizationid === 2) {
        HTTP.get('/organization/get/filter-options').then(response => {
          this.organizationFilterOptions = response.data
        }).catch(() => {
          this.organizationFilterOptions = []
        })
      } else {
        HTTP.get(`/organization/get/filter-options?organization-id=${this.organizationid}`).then(response => {
          this.organizationFilterOptions = response.data
        }).catch(() => {
          this.organizationFilterOptions = []
        })
      }
    },
    newitem() {
      this.newItem = true
      this.editedItem = { id: 0, firstname: null, lastname: null, email: null, password: null, organizationId: this.organizationid, role: 0 }
      this.editItem(this.editedItem)
    },
    editItem(item) {
      this.idOfLastSelectedRow = item.id
      this.editedItem = Object.assign({}, item)
      this.showpassword = false;
      this.dialog = true
      setTimeout(() => {
        this.$refs.firstName.focus()
      }, 200)
      if (this.$refs.userform) this.$refs.userform.resetValidation()
    },
    deleteItem(item) {
      var self = this
      if (item.workspaceIds.length > 0) {
        this.$root.$ConfirmDialog.open('Deleting primary user', 'You are about to delete a primary user for one of your workspaces.<br/><br/> <strong>Are you sure?</strong>', { color: 'red' }).then((response) => {
          if (response) {
            HTTP.post('/users/delete', { id: item.id }).then(() => {
              self.tableItems.splice(self.tableItems.indexOf(item), 1)
            }).catch(e => {
              self.$store.dispatch('showError', e.response.data)
            })
          }
        })
      }
      else {
        this.$root.$ConfirmDialog.open('Delete item', 'Are you sure?', { color: 'red' }).then((response) => {
          if (response) {
            HTTP.post('/users/delete', { id: item.id }).then(() => {
              self.tableItems.splice(self.tableItems.indexOf(item), 1)
            }).catch(e => {
              self.$store.dispatch('showError', e.response.data)
            })
          }
        })
      }
    },
    save() {
      if (this.editedItem.isSSOUser) {
        this.editedItem.email = this.editedItem.email.substring(0, this.editedItem.email.length - 6)
      }
      HTTP.post('/users/save', this.editedItem).then(response => {
        if (response.data.id > 0) {
          var userIndex = this.tableItems.findIndex(user => user.id === response.data.id)
          if (userIndex !== -1) {
            this.tableItems.splice(userIndex, 1, response.data)
          } else {
            this.tableItems.push(response.data)
          }
        } else {
          this.editedItem = response.data
          this.tableItems.push(this.editedItem)
        }
        this.dialog = false
      }).catch(e => {
        this.$store.dispatch('showError', 'Error when saving:<br/><br/>' + e.response.data)
      })
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27 && this.enableEscClear) {
        this.search = ''
      }
    },
  }
}
</script>
