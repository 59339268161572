<template>
  <div style="width: 100%; padding: 0 32px; display: flex; flex-direction: column; justify-content: center;">
    <v-progress-linear v-if="loading" :active="loading" indeterminate color="blue" style=""></v-progress-linear>
    <div v-if="hasOpenReport" justify="center" class="mb-4">
      <v-alert v-if="openReports[openReports.length - 1].status === 0" type="error"
        style="max-width: 270px; margin: 0 auto;">
        <div style="text-align: start;">
          Item has been reported.
        </div>
        <div style="text-align: start;">
          {{ formatTimeReport }}
        </div>
        <div style="text-align: start;">
          Status:
          {{ getStatustext(openReports[openReports.length - 1].status) }}
        </div>
      </v-alert>
      <v-alert v-else icon="mdi-alert" type="warning" style="max-width: 270px; margin: 0 auto;">
        <div style="text-align: start;">
          Item has been reported.
        </div>
        <div style="text-align: start;">
          {{ formatTimeReport }}
        </div>

        <div style="text-align: start;">
          Status:
          {{ getStatustext(openReports[openReports.length - 1].status) }}
        </div>

      </v-alert>
    </div>
    <div style="width: 100%; display: flex; justify-content: center;">
      <div style="width: 100% ;max-width: 250px; display: flex; flex-direction: column;">
        <div v-if="condition" class="center-obj mb-4">
          <v-expansion-panels multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-title>
                <div class="instruction-header">{{ instructionHeader || 'Instructions' }}</div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="instruction"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="infotext text-center mb-4">Take a picture and/or<br />write a message</div>
        <div id="errorpic" class="mb-4">
          <div class="mobileUpload">
            <input id="single-file-input" @change="singleFileChange($event)" type="file" name="photos" accept="image/*"
              capture="environment" style="display:none;">
            <v-btn class="widebutton" style="width: 100%;overflow: hidden; position: relative;"
              @mousedown="uploadReplacementImage">
              <v-icon size="35px" style="z-index: 10;">mdi-camera</v-icon>
              <img v-if="hasImage" style="position: absolute; max-width: 230px;" :src="imgPreviewSrc">
            </v-btn>
          </div>
        </div>
        <div class="mb-4">
          <v-form ref="contactform" v-model="isFormValid">
            <div style="display: flex; flex-direction: column; justify-content: center;">
              <v-textarea required :rules="requiredRules" v-model="message" outlined class="messagearea mb-4"
                hide-details label="* Message"></v-textarea>
              <v-text-field v-model="email" validate-on-blur :rules="emailRules" class="messagearea smalltext"
                type="email" label="Email" solo></v-text-field>
            </div>
          </v-form>
        </div>
        <div class="mb-8" style="display: flex; justify-content: space-between">
          <v-btn class="smallbutton" @mousedown="$router.go(-1)" :loading="sending" :disabled="sending">Back</v-btn>
          <v-btn class="smallbutton" @mousedown="save()" :loading="sending" :disabled="!isFormValid">Send
            <template v-slot:loader>
              <span>Sending...</span>
            </template>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'

const emailRegex = /^$|null|\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export default {
  props: ['qrdetailid', 'qrstring', 'workspaceid', 'qrworkspaceid'],
  data() {
    return {
      qrname: null,
      message: null,
      email: null,
      localpicture: {},
      imgPreviewSrc: null,
      saving: false,
      saved: false,
      loading: false,
      hasImage: false,
      sending: false,
      instruction: null,
      instructionHeader: null,
      openReports: [],
      panel: [],

      requiredRules: [
        v => !!v || 'Field is required',
      ],
      emailRequirementRegex: new RegExp("^$|null"),
      emailRequirementHint: "Email is in incorrect format",
      emailRules: [
        v => (!!emailRegex.test(v)) || 'Email is in incorrect format',
        v => (!!this.testEmailRequirementRegex(v)) || this.emailRequirementHint,
      ],
      isFormValid: false,
    }
  },
  created() {
    HTTP.get('/mobil/getinventorytype/' + parseInt(this.qrdetailid)).then(response => {
      this.qrname = response.data.qrDetailInfo.qrname
      this.instruction = response.data.qrDetailInfo.instruction
      this.instructionHeader = response.data.qrDetailInfo.instructionHeader
      this.openReports = response.data.openReports
    })
    HTTP.get(`/mobil/get/email-Requirement?id=${this.qrdetailid}`).then((response) => {
      console.log(response.data)
      this.emailRequirementRegex = new RegExp("^$|null|" + response.data.emailRequirementRegex)
      if (response.data.emailRequirementHint !== null) {
        this.emailRequirementHint = response.data.emailRequirementHint
      }
    }).catch(() => { })
  },
  computed: {
    hasOpenReport() {
      return this.openReports.length > 0;
    },
    formatTimeReport() {
      const reportTime = new Date(this.openReports[this.openReports.length - 1].dateReported)
      return `Time:
          ${reportTime.getFullYear()}-${(reportTime.getMonth() + 1).toString().length == 1 ? "0" + (reportTime.getMonth() + 1) : reportTime.getMonth() + 1}-${reportTime.getDate().toString().length == 1 ? "0" + reportTime.getDate() : reportTime.getDate()}
          ${reportTime.getHours().toString().length == 1 ? "0" + reportTime.getHours() : reportTime.getHours()}:${reportTime.getMinutes().toString().length == 1 ? "0" + reportTime.getMinutes() : reportTime.getMinutes()}`
    },
    condition() {
      return this.instruction !== null &&
        this.instruction !== '' &&
        this.instruction !== '<p></p>' &&
        this.instruction !== '<h1></h1>' &&
        this.instruction !== '<h2></h2>' &&
        this.instruction !== '<h3></h3>';
    },
  },
  methods: {
    testEmailRequirementRegex(email) {
      if (email !== null) {
        email = email.toLowerCase()
      }
      return !!this.emailRequirementRegex.test(email)
    },
    save() {
      if (!(JSON.stringify(this.localpicture) === '{}' && this.message === null)) {
        this.loading = true
        this.sending = true
        this.saveattachment()
      } else {
        this.$store.dispatch('showError', 'Enter message and/or take picture')
      }
    },
    uploadReplacementImage() {
      document.getElementById('single-file-input').click();
    },
    singleFileChange(e) {
      if (!e.target.files.length) {
        return;
      }
      this.hasImage = true;
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.imgPreviewSrc = reader.result
        this.localpicture = {
          filedata: this.imgPreviewSrc.replace('data:', '').replace(/^.+,/, ''),
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
        }
      }
      reader.readAsDataURL(file);
    },
    saveattachment() {
      HTTP.post('/mobil/sendfelrapport', {
        qrdetailid: parseInt(this.qrdetailid),
        message: this.message,
        email: this.email === "" ? null : this.email,
        picture: this.localpicture,

      }).then(() => {
        setTimeout(() => {
          this.loading = false
        }, 100)
        if (this.qrstring != undefined) {
          this.$router.push({ name: 'MobileLegacyReportSuccess' });
        } else if (this.workspaceid != undefined && this.qrworkspaceid != undefined) {
          this.$router.push({ name: 'MobileReportSuccess' });
        }
      }).catch(e => {
        setTimeout(() => {
          this.loading = false
        }, 100)
        this.$store.dispatch('showError', e.response.data)
      })
    },
    getStatustext(status) {
      switch (status) {
        case 0:
          return "Waiting"
        case 1:
          return "Ongoing"
        case 2:
          return "Closed"
      }
    },
  }
}
</script>
<style>
#fileInput {
  display: none;
}

.instruction-header {
  font-weight: bold;
}

.center-obj {
  display: flex;
  flex-direction: column;
  place-items: center;
}
</style>
